<template>
  <div>
    <div class="air__utils__heading"> <h5>Detalle de Manifiesto</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <my-spinner :load="fetching"/>
    <div v-if="!fetching">
      <a-row type="flex" justify="end">
        <a-col :span="3">
          <b-button variant="warning" pill class="btn-block" :to="{name: 'print-finished-product-manifests', params: {id: id}}">
            <div class="d-none d-md-block">
              <b-icon icon="file-earmark-richtext"/> Imprimir Manifiesto
            </div>
            <div class="d-md-none">
              <b-icon icon="file-earmark-richtext"/>
            </div>
          </b-button>
        </a-col>
      </a-row>
      <a-row class="mt-4">
        <a-descriptions bordered size="small" :column="{ xs: 1, sm: 1, md: 2, lg: 4}">
          <a-descriptions-item label="Planta">
            <strong v-if="manifest.work_centers && manifest.work_centers.name">{{manifest.work_centers.name}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="No.Lote">
            <strong>{{manifest.manifest_batch_code}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="No.Manifiesto de Salida y Disposición Fiscal">
            <strong>{{manifest.exit_manifest_code}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Tipo de Formulación">
            <strong v-if="manifest.formulation_types && manifest.formulation_types.name">{{manifest.formulation_types.name}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Fecha de reciclaje">
            <strong>{{moment(manifest.recycle_dated_at).format('DD/MM/YYYY')}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Cantidad Total">
            <strong>{{manifest.quantity}} -
             <span v-if="manifest.ums"> {{manifest.ums.name}}</span>
            </strong>
          </a-descriptions-item>
        </a-descriptions>
      </a-row>
    </div>
    <hr class="bg-primary">
    <a-row>
      <a-col :sm="tableSizeCol.sm" :md="tableSizeCol.md">
        <h5 class="text-center">Mezclas</h5>
        <hr class="bg-primary">
        <a-table bordered :scroll="{y:1000}"
            :columns="tableObj.columns" :data-source="manifest.finished_product_manifest_mixtures"
            :row-key="record => record.mixtures.id"
            :row-selection="{ width: 100, fixed:true, type: 'radio', selectedRowKeys: selectedRowKeys, selectedRows: selectedRow, onChange: onSelectChange }">
            <mixtureTableInfo slot="mixtureTableInfo" slot-scope="row" :mixture="row.mixtures"/>
        </a-table>
      </a-col>
      <a-col :sm="{ span: 24 }" :md="{ span: 10, offset:1 }" v-if="selectedRow.length > 0">
        <h5 class="text-center">Listado de Residuos de la Mezcla</h5>
        <hr class="bg-primary">
        <a-table v-if="selectedRow.length > 0 && Object.keys(selectedRow[0]).length > 0 && selectedRow[0].mixtures && selectedRow[0].mixtures.mixture_items.length > 0"
            bordered :columns="mixturesTitemsColumns"
            :data-source="selectedRow[0].mixtures.mixture_items" :row-key="record => record.id">
            <template slot="details" slot-scope="row">
              <a-descriptions :title="`${row.t_items.consecutive} - ${row.t_items.code}`" bordered size="small" :column="{xs: 1, sm: 1, lg:2} ">
                <a-descriptions-item label="Transacción">
                  <strong>{{row.t_items.t_details[0].transactions.code}}</strong>
                </a-descriptions-item>
                <a-descriptions-item label="Residuo">
                  <strong>{{row.t_items.t_details[0].sap_material_contracts.materials.name}}</strong>
                </a-descriptions-item>
                <a-descriptions-item label="Cantidad">
                  <strong>{{row.t_items.quantity}}</strong>
                </a-descriptions-item>
                <a-descriptions-item label="Contenedor">
                  <strong>{{row.t_items.t_details[0].container_types.name}}</strong>
                </a-descriptions-item>
                <a-descriptions-item label="Almacén">
                  <strong>{{row.t_items.t_details[0].transactions.warehouses.name}}</strong>
                </a-descriptions-item>
              </a-descriptions>
            </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
import mixtureTableInfo from './mixtureTableInfo.vue'
export default {
  name: 'finishedProductManifests',
  mixins: [apiV2Mixin],
  components: { mixtureTableInfo },
  data() {
    return {
      id: this.$route.params.id,
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          breadcrumbName: 'Proceso Operativo',
          name: 'operation_process',
        },
        {
          breadcrumbName: 'Almacén de Producto Terminado',
          name: 'finished_products',
        },
        {
          breadcrumbName: 'Detalle de Manifiesto',
          aIcon: '',
        },
      ],
      // dom
      fetching: false,
      // manifest
      manifest: {
        id: 0,
        manifest_batch_code: '',
        exit_manifest_code: '',
        recycle_dated_at: '',
        quantity: '',
        carrier_company_name: '',
        carrier_company_driver: '',
        vehicle_type: '',
        vehicle_tag: '',
        vehicle_tag_coupled: '',
        semarnat_2: '',
        external_relations_secretary: '',
        exit_type: '',
        programming_code: '',
        business_name: '',
        authorization_final_destiny: '',
        authorization_final_code: '',
        responsible: '',
        is_blocked: '1',
        work_centers: {
          name: '',
        },
        ums: {
          name: '',
        },
        finished_product_manifest_mixtures: [
          {
            mixtures: {
              id: 2,
              code: '',
              name: '',
              row: '',
              drawer: '',
              total_formule_material: '',
              is_blocked: true,
              storage_area_finished_product: 'No Aplica',
              formulations: {
                code: '',
                operation_technical_1: '',
                operation_technical_2: '',
                worked_hours: '',
                started_at: '',
                finished_at: '',
                status: 'Concluido',
                total_containers: '',
                operation_shifts: {
                  code: '',
                  name: '',
                  initial_time: '',
                  final_time: '',
                },
                formulation_types: {
                  name: '',
                  details: '',
                },
                process_types: {
                  code: '',
                  name: '',
                },
              },
              currents: {
                name: '',
              },
              finished_product_areas: {
                name: '',
              },
            },
          },
        ],
      },
      // tables
      tableObj: {
        columns: [
          { title: 'Detalles', width: 'auto', class: 'text-center', scopedSlots: { customRender: 'mixtureTableInfo' } },
        ],
      },
      selectedRowKeys: [],
      selectedRow: [],
      mixturesTitemsColumns: [
        {
          title: 'Información General',
          key: 'detail',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'details',
          },
        },
      ],
    }
  },
  computed: {
    tableSizeCol () {
      if (this.selectedRow.length > 0) {
        return { sm: { span: 24 }, md: { span: 13 } }
      } else return { sm: { span: 24 }, md: { span: 24 } }
    },
  },
  methods: {
    async Fetch() {
      this.fetching = true
      const url = `finishedProductManifests/${this.id}`
      const manifest = await this.AxiosGetv2(url, {})
      if (manifest && manifest.data && Object.keys(manifest.data)) {
        this.manifest = manifest.data
      }
      this.fetching = false
    },
    // Table selection Item
    async onSelectChange(_selectedRowKeys, selectedRows) {
      this.selectedRow = selectedRows
      this.selectedRowKeys = _selectedRowKeys
    },
  },
  async mounted() {
    if (this.id) {
      await this.Fetch()
    }
  },

}
</script>
